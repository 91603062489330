/** @jsx jsx */
import { FC, useState, useContext, useCallback, useEffect } from "react"
import { useSpring } from "react-spring"
import { jsx, Text } from "theme-ui"

import { Hero } from "components/Hero"
import { HeroTextLockup } from "components/HeroTextLockup"
import { Odometer } from "components/Odometer"
import { ScrollContext } from "components/Layout"
import { SquareAnimated } from "components/SquareAnimated"
import { useEngJobOpeningsCount } from "hooks/use-jobs-by-deparment"

export const HeroJobs: FC = () => {
  const [count, setCount] = useState(12)
  const [jobCount, setJobCount] = useState(12)
  const { scrolled } = useContext(ScrollContext)

  const scale = scrolled ? 0.9 : 1

  const [springProps, set] = useSpring(
    () => ({
      xyscale: [0, 0, scale],
      opacity: scrolled ? 0.9 : 1,
      from: {
        xyscale: [0, 0, scale],
        opacity: 0,
      },
      config: { mass: 10, tension: 550, friction: 140 },
    }),
    [scrolled]
  )

  const calc = (x: number, y: number) => [
    x - window.innerWidth / 2,
    y - window.innerHeight / 2,
  ]

  const onMove = useCallback(
    ({ clientX: x, clientY: y }: { clientX: number; clientY: number }) =>
      void set({ xyscale: [...calc(x, y), scale] }),
    []
  )

  useEffect(() => {
    const fetchData = async () => {
      const count = await useEngJobOpeningsCount()
      setJobCount(count)
      setCount(count)
    }
    fetchData()
  }, [])

  useEffect(() => {
    const count = scrolled ? 12 : jobCount
    setCount(count)
  }, [scrolled])

  return (
    <Hero
      onMouseMove={onMove}
      alignImage="left"
      image={
        <SquareAnimated
          springProps={springProps}
          sx={{ boxShadow: "heroJobs" }}
        >
          <Text
            sx={{
              "div ::selection": {
                bg: "backgroundSelectionDark",
              },
              color: "textWhite",
              width: "100%",
              height: "100%",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              fontWeight: 300,
              ml: "-2%",
              fontSize: [21, null, 19, "177.747px", "236.996px"],
            }}
          >
            <Odometer count={count} />
          </Text>
        </SquareAnimated>
      }
    >
      <HeroTextLockup
        headingArray={["Jobs openings"]}
        paragraph={`Front-end, back-end, data, automation, and more. Our engineers ship
        code 50-100 times and impact every part of what makes our business
        thrive.`}
      />
    </Hero>
  )
}
