import * as React from "react"
import { FC } from "react"
import { useInView } from "react-intersection-observer"

import { AnimatedHeading } from "components/AnimatedHeading"
import { Job } from "components/Job"
import { Box, Divider, Grid, Heading, Text } from "theme-ui"

export interface IPropsDepartment {
  count: number
  name: string
  jobs: Job[]
}

interface Job {
  id: string
  title: string
  location: {
    name: string
  }
  updated_at: string
  absolute_url: string
}

const Department: FC<IPropsDepartment> = ({ name, count, jobs }) => {
  const [ref, inView, entry] = useInView({ threshold: 0.5 })
  const belowViewport = !inView && entry && entry.intersectionRect.top > 0

  return (
    <Box>
      <Divider
        sx={{
          height: "1px",
          opacity: 0.3,
          margin: 0,
          border: 0,
          borderBottom: "1px solid",
          borderColor: "border",
        }}
      />
      <Grid variant="base" sx={{ py: [11, 13, null, null, 14, 15] }}>
        <Box
          sx={{
            gridColumn: "1/12",
            gridRow: [null, null, "1/2"],
          }}
        >
          <Grid
            sx={{
              mb: [8, 9],
              position: [null, null, "sticky"],
              top: [null, null, 12, 13],
            }}
          >
            <Heading
              as="h2"
              sx={{
                letterSpacing: "tight5",
                gridColumn: "span 12",
                lineHeight: 1.1,
                fontSize: [10, 12, 11, 12, 14, 15],
              }}
            >
              <Box
                ref={ref}
                sx={{
                  display: "flex",
                  alignItems: "baseline",
                  flexDirection: "column",
                }}
              >
                <AnimatedHeading
                  headline={[name]}
                  visible={!belowViewport}
                  delay={200}
                  sx={{ pb: 2 }}
                />
                <Text
                  sx={{
                    color: "textMutedMore",
                    gridColumn: ["2/13", "span 12"],
                    fontSize: [3, 4, 3, 4, 5],
                    fontWeight: 400,
                  }}
                >
                  {count} open {count === 1 ? "role" : "roles"}
                </Text>
              </Box>
            </Heading>
          </Grid>
        </Box>
        <Box
          as="ul"
          sx={{
            position: "relative",
            gridColumn: ["2 / 13", null, "5/12"],
            gridRow: [null, null, "1/2"],
            top: [null, null, "-8px", null, "-4px"],
          }}
        >
          {jobs.map((job) => (
            <Box
              as="li"
              key={job.id}
              sx={{
                mb: [9, 10, null, 5, 6],
                ":last-child": {
                  mb: 0,
                },
              }}
            >
              <Job
                title={job.title}
                location={job.location.name}
                updatedAt={job.updated_at}
                url={job.absolute_url}
              />
            </Box>
          ))}
        </Box>
      </Grid>
    </Box>
  )
}

export default Department
