import * as React from "react"
import { FC } from "react"
import { Link, Heading, Text, Box } from "theme-ui"
import { useInView } from "react-intersection-observer"
import { formatDistance } from "date-fns"

import { AnimatedHeading } from "components/AnimatedHeading"

export interface IPropsJob {
  location: string
  title: string
  updatedAt: string
  url: string
}

export const Job: FC<IPropsJob> = ({ title, location, updatedAt, url }) => {
  const [ref, inView, entryText] = useInView({ threshold: 1 })
  const belowViewportText =
    !inView && entryText && entryText.intersectionRect.top > 0

  const date = formatDistance(new Date(updatedAt), new Date(), {
    addSuffix: true,
  })

  return (
    <Link
      sx={{
        height: "100%",
        width: "100%",
        display: "inline-block",
        position: "relative",
        textDecoration: "none",
        color: "text",

        ":focus-visible": {
          outline: "1px dashed",
        },
      }}
      href={url}
      target="_blank"
    >
      <Box
        sx={{
          p: [null, null, 5, null, 6],

          "a:hover &": {
            bg: [null, null, "backgroundPrimary"],
            color: "textWhite",
          },

          "a:active &": {
            filter: [null, null, "brightness(0.95)"],
            color: "textWhite",
          },
        }}
        ref={ref}
      >
        <Heading
          as="h3"
          sx={{
            lineHeight: 1.2,
            fontSize: [5, 7, 6, 7, 8, 9],
            fontWeight: 400,
            mb: [1, 2, null],
          }}
        >
          <AnimatedHeading
            headline={[title]}
            visible={!belowViewportText}
            delay={200}
          />
        </Heading>
        <Box sx={{ display: "flex", alignItems: "baseline" }}>
          <Text
            sx={{
              color: "currentColor",
              fontSize: [2, 3, 2],
            }}
          >
            {location}
          </Text>
          <Text
            sx={{
              color: "currentColor",
              fontSize: [1, 2, 1],
            }}
          >
            <Box as="span" sx={{ mx: 2 }}>
              —
            </Box>
            {date}
          </Text>
        </Box>
      </Box>
    </Link>
  )
}
