import { FC } from "react"
import * as React from "react"
import { Box } from "theme-ui"

import { Footer } from "components/Footer"
import { GridOverlay } from "components/GridOverlay"
import { HeroJobs } from "components/HeroJobs"
import { JobsList } from "components/JobsList"
import { Layout } from "components/Layout"
import { SEO } from "components/Seo"

export const JobsPage: FC = () => (
  <Layout>
    <SEO title="Jobs at Better" location="/careers" />
    <Box sx={{ bg: "backgroundPrimary" }}>
      <HeroJobs />
      <JobsList />
    </Box>
    <Footer />
    <GridOverlay />
  </Layout>
)

export default JobsPage
