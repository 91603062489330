import * as React from "react"
import { FC } from "react"
import { Box } from "theme-ui"
import { OdometerNumber } from "./Number"

export interface IPropsOdometer {
  count?: number
}

export const Odometer: FC<IPropsOdometer> = ({ count = 10 }) => {
  const chars = String(count).split("")
  return (
    <Box sx={{ display: "inline-block", height: "1em", lineHeight: "1em" }}>
      {chars.map((digit, i) => {
        return <OdometerNumber key={i} digit={digit} />
      })}
    </Box>
  )
}
