export const useJobOpenings = async () => {
  let json
  try {
    const response = await fetch(
      `https://boards-api.greenhouse.io/v1/boards/better/jobs?content=true`
    )
    json = await response.json()
    return json
  } catch (error) {
    console.error(error)
  }
}
