import * as React from "react"
import { FC } from "react"
import { animated, useSpring } from "react-spring"
import { Box } from "theme-ui"

export interface IPropsOdometerNumber {
  digit: string
}

export const OdometerNumber: FC<IPropsOdometerNumber> = ({ digit }) => {
  const { y, opacity } = useSpring({
    y: -digit,
    opacity: 1,
    from: { y: 0, opacity: 0 },
  })

  return (
    <Box
      sx={{
        display: "inline-block",
        width: "0.6em",
        height: "1em",
        overflow: "hidden",
      }}
    >
      <animated.div
        style={{
          opacity,
          transform: y.to((value: number) => `translateY(${value}em)`),
        }}
      >
        0 1 2 3 4 5 6 7 8 9 0
      </animated.div>
    </Box>
  )
}
