import { useJobOpenings } from "./use-job-openings"

const roles = ["Engineering", "Product", "Design"]

const getEngDepartmentsUniqueIds = (jobs) => {
  let departmentsId = []
  jobs.forEach((j) => {
    j.departments.forEach((d) => {
      if (!departmentsId.includes(d.id) && isEngDepartment(d.name))
        departmentsId.push(d.id)
    })
  })
  return departmentsId
}

const isEngDepartment = (name) => {
  const keywords = ["Engineering", "Design"]
  return keywords.find((k) => name.toLowerCase().includes(k.toLowerCase()))
}

const filterJobsByDepartment = (departmentIds, jobs) => {
  const mapped = departmentIds.map((id) => {
    const match = []
    jobs.filter((j) => {
      j.departments.forEach((d) => {
        if (d.id === id) match.push(j)
      })
    })
    const name = match[0].departments.find((d) => d.id === id).name
    return {
      id,
      name,
      jobs: match,
      count: match.length,
    }
  })
  return mapped
}

export const useJobsByDepartment = async () => {
  const response = await useJobOpenings()
  const departmentIds = getEngDepartmentsUniqueIds(response.jobs)
  const jobsByDepartment = filterJobsByDepartment(departmentIds, response.jobs)
  return jobsByDepartment
}

const jobCounter = (counter, currentValue) => {
  const isEngRole = currentValue.departments.some((r) =>
    isEngDepartment(r.name)
  )
  if (isEngRole) counter++
  return counter
}

export const useEngJobOpeningsCount = async () => {
  const { jobs } = await useJobOpenings()
  return jobs.reduce(jobCounter, 0)
}
