import * as React from "react"
import { FC, useEffect, useState } from "react"
import { Container, Text, Box } from "theme-ui"

import Department from "components/Department"
import { useJobsByDepartment } from "hooks/use-jobs-by-deparment"

export interface IPropsJobList {
  className?: string
}

export const JobsList: FC<IPropsJobList> = ({ className }) => {
  const [{ loading, jobsByDepartment }, setData] = useState({
    loading: true,
    jobsByDepartment: [],
  })

  useEffect(() => {
    const getJobs = async () => {
      const jobsByDepartment = await useJobsByDepartment()
      setData({
        loading: false,
        jobsByDepartment,
      })
    }
    getJobs()
  }, [])

  return (
    <>
      <Container sx={{ bg: "background" }}>
        <Box as="ul">
          {loading && <Text>Loading...</Text>}
          {jobsByDepartment.length > 0 &&
            jobsByDepartment.map((department) => (
              <Box as="li" key={department.id} sx={{ mb: [7] }}>
                <Department {...department} />
              </Box>
            ))}
          {!loading && jobsByDepartment.length === 0 && (
            <Text>Sorry, no jobs at the moment.</Text>
          )}
        </Box>
      </Container>
    </>
  )
}
